import type { Self } from '@/store/types';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';

export async function getPermsForConfig(modelName: ModelName) {
  const queryCache = window.queryClient?.getQueryCache();
  const data = queryCache?.find<Self>({ queryKey: ['self'] })?.state.data;
  if (!data) {
    return {
      overall: {
        canCreate: false,
        canUpdate: false,
        canView: false,
        canDelete: false,
      },
      fields: {},
    };
  }

  return data?.permissions?.[modelName];
}

export async function getPerms<Instance extends BaseInstance = BaseInstance>(
  modelName?: ModelName
) {
  if (!modelName) {
    return {
      canView: true,
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      canHandle: true,
    };
  }
  const allPerms = await getPermsForConfig(modelName);

  return {
    ...allPerms?.overall,
    fields: allPerms?.fields as {
      [key in keyof Instance]: {
        // [key: `can${string}`]: boolean; Should not be used right now, maybe never, maybe later alligator
        canView: boolean;
        canHandle: boolean;
      };
    },
  };
}

export const structuralPerms = [];
