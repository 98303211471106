import { cn } from '@/lib/cn';
import type { ModelName } from '@pigello/pigello-matrix';
import { Icons } from '../icons';

export const getTabIcon = (
  modelName: ModelName,
  className?: string
): JSX.Element | null => {
  const iconClass = cn('size-4 min-h-[16px] min-w-[16px]', className);

  const icons: Partial<Record<ModelName, JSX.Element>> = {
    tenant: <Icons.tennant className={iconClass} />,
    building: <Icons.building className={iconClass} />,
    realestate: <Icons.building className={iconClass} />,
    apartment: <Icons.building className={iconClass} />,
    parkingspot: <Icons.building className={iconClass} />,
    parkinglot: <Icons.building className={iconClass} />,
    outdoorsection: <Icons.building className={iconClass} />,
    outdoorarea: <Icons.building className={iconClass} />,
    company: <Icons.building className={iconClass} />,
    region: <Icons.building className={iconClass} />,
    commonarea: <Icons.building className={iconClass} />,
    industrialpremises: <Icons.building className={iconClass} />,
    apartmentcontract: <Icons.building className={iconClass} />,
    industrialpremisescontract: <Icons.building className={iconClass} />,
    outdoorsectioncontract: <Icons.building className={iconClass} />,
    parkingspotcontract: <Icons.building className={iconClass} />,
    othercontract: <Icons.building className={iconClass} />,
    blockcontract: <Icons.building className={iconClass} />,
    organizationuser: <Icons.building className={iconClass} />,
    signabledocument: <Icons.building className={iconClass} />,
    invoice: <Icons.building className={iconClass} />,
    invoicepayment: <Icons.building className={iconClass} />,
    monitoringconfiguration: <Icons.building className={iconClass} />,
    sensor: <Icons.building className={iconClass} />,
    brfapartment: <Icons.building className={iconClass} />,
    automaticdistributionquota: <Icons.building className={iconClass} />,
    notification: <Icons.building className={iconClass} />,
    measurementseries: <Icons.building className={iconClass} />,
    verificationgroup: <Icons.building className={iconClass} />,
    verification: <Icons.building className={iconClass} />,
  };

  return icons[modelName] ?? null;
};
