import type { Widget } from '../types';
import { widgetColors } from '../utils';

export const toHandleWidgetData: Widget[] = [
  {
    name: 'Att hantera - Lägenhetsavtal',
    category: 'to_handle',
    sub_category: 'apartment_contracts',
    modelNames: ['apartmentcontract'],
    statistics: [
      {
        name: 'Uppsagda lägenhetsavtal',
        identifier: 'to_handle.apartment_contracts.warning_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'apartmentcontract',
      },
      {
        name: 'Utgående lägenhetsavtal',
        identifier: 'to_handle.apartment_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'apartmentcontract',
      },
      {
        name: 'Ej signerad lägenhetsavtal',
        identifier: 'to_handle.apartment_contracts.not_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'apartmentcontract',
      },
      {
        name: 'Kommande inflyttningar',
        identifier: 'to_handle.apartment_contracts.upcoming_contracts',
        pinned: false,
        color: widgetColors.blue,
        modelName: 'apartmentcontract',
      },
      {
        name: 'Avtal under pågående signering',
        identifier: 'to_handle.apartment_contracts.currently_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'apartmentcontract',
      },
      {
        name: 'Avbrutna signeringar',
        identifier: 'to_handle.apartment_contracts.cancelled_signed_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'apartmentcontract',
      },
    ],
  },
  {
    name: 'Att hantera - Lokalavtal',
    category: 'to_handle',
    sub_category: 'industrialpremises_contracts',
    modelNames: ['industrialpremisescontract'],
    statistics: [
      {
        name: 'Uppsagda lokalavtal',
        identifier: 'to_handle.industrialpremises_contracts.warning_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'industrialpremisescontract',
      },
      {
        name: 'Utgående lokalavtal',
        identifier:
          'to_handle.industrialpremises_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'industrialpremisescontract',
      },
      {
        name: 'Ej signerad lokalavtal',
        identifier:
          'to_handle.industrialpremises_contracts.not_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'industrialpremisescontract',
      },
      {
        name: 'Kommande lokalavtal',
        identifier: 'to_handle.industrialpremises_contracts.upcoming_contracts',
        pinned: false,
        color: widgetColors.blue,
        modelName: 'industrialpremisescontract',
      },
      {
        name: 'Avtal under pågående signering',
        identifier:
          'to_handle.industrialpremises_contracts.currently_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'industrialpremisescontract',
      },
      {
        name: 'Avbrutna signeringar',
        identifier:
          'to_handle.industrialpremises_contracts.cancelled_signed_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'industrialpremisescontract',
      },
    ],
  },
  {
    name: 'Att hantera - Parkeringsavtal',
    category: 'to_handle',
    sub_category: 'parkingspot_contracts',
    modelNames: ['parkingspotcontract'],
    statistics: [
      {
        name: 'Uppsagda parkeringsavtal',
        identifier: 'to_handle.parkingspot_contracts.warning_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'parkingspotcontract',
      },
      {
        name: 'Utgående parkeringsavtal',
        identifier: 'to_handle.parkingspot_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'parkingspotcontract',
      },
      {
        name: 'Ej signerad parkeringsavtal',
        identifier: 'to_handle.parkingspot_contracts.not_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'parkingspotcontract',
      },
      {
        name: 'Kommande parkeringsavtal',
        identifier: 'to_handle.parkingspot_contracts.upcoming_contracts',
        pinned: false,
        color: widgetColors.blue,
        modelName: 'parkingspotcontract',
      },
      {
        name: 'Avtal under pågående signering',
        identifier:
          'to_handle.parkingspot_contracts.currently_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'parkingspotcontract',
      },
      {
        name: 'Avbrutna signeringar',
        identifier:
          'to_handle.parkingspot_contracts.cancelled_signed_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'parkingspotcontract',
      },
    ],
  },
  {
    name: 'Att hantera - Utomhussektionsavtal',
    category: 'to_handle',
    sub_category: 'outdoorsection_contracts',
    modelNames: ['outdoorsectioncontract'],
    statistics: [
      {
        name: 'Uppsagda utomhussektionsavtal',
        identifier: 'to_handle.outdoorsection_contracts.warning_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'outdoorsectioncontract',
      },
      {
        name: 'Utgående utomhussektionsavtal',
        identifier: 'to_handle.outdoorsection_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'outdoorsectioncontract',
      },
      {
        name: 'Ej signerad utomhussektionsavtal',
        identifier: 'to_handle.outdoorsection_contracts.not_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'outdoorsectioncontract',
      },
      {
        name: 'Kommande utomhussektionsavtal',
        identifier: 'to_handle.outdoorsection_contracts.upcoming_contracts',
        pinned: false,
        color: widgetColors.blue,
        modelName: 'outdoorsectioncontract',
      },
      {
        name: 'Avtal under pågående signering',
        identifier:
          'to_handle.outdoorsection_contracts.currently_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'outdoorsectioncontract',
      },
      {
        name: 'Avbrutna signeringar',
        identifier:
          'to_handle.outdoorsection_contracts.cancelled_signed_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'outdoorsectioncontract',
      },
    ],
  },
  {
    name: 'Att hantera - Övriga avtal',
    category: 'to_handle',
    sub_category: 'other_contracts',
    modelNames: ['othercontract'],
    statistics: [
      {
        name: 'Uppsagda övriga avtal',
        identifier: 'to_handle.other_contracts.warning_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'othercontract',
      },
      {
        name: 'Utgående övriga avtal',
        identifier: 'to_handle.other_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'othercontract',
      },
      {
        name: 'Ej signerad övriga avtal',
        identifier: 'to_handle.other_contracts.not_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'othercontract',
      },
      {
        name: 'Kommande övriga avtal',
        identifier: 'to_handle.other_contracts.upcoming_contracts',
        pinned: false,
        color: widgetColors.blue,
        modelName: 'othercontract',
      },
      {
        name: 'Avtal under pågående signering',
        identifier: 'to_handle.other_contracts.currently_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'othercontract',
      },
      {
        name: 'Avbrutna signeringar',
        identifier: 'to_handle.other_contracts.cancelled_signed_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'othercontract',
      },
    ],
  },
  {
    name: 'Att hantera - Blockavtal',
    category: 'to_handle',
    sub_category: 'block_contracts',
    modelNames: ['blockcontract'],
    statistics: [
      {
        name: 'Uppsagda blockavtal',
        identifier: 'to_handle.block_contracts.warning_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'blockcontract',
      },
      {
        name: 'Utgående blockavtal',
        identifier: 'to_handle.block_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'blockcontract',
      },
      {
        name: 'Ej signerad övriga avtal',
        identifier: 'to_handle.block_contracts.not_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'blockcontract',
      },
      {
        name: 'Kommande blockeringsavtal',
        identifier: 'to_handle.block_contracts.upcoming_contracts',
        pinned: false,
        color: widgetColors.blue,
        modelName: 'blockcontract',
      },
      {
        name: 'Avtal under pågående signering',
        identifier: 'to_handle.block_contracts.currently_signed_contracts',
        pinned: false,
        color: widgetColors.purple,
        modelName: 'blockcontract',
      },
      {
        name: 'Avbrutna signeringar',
        identifier: 'to_handle.block_contracts.cancelled_signed_contracts',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'blockcontract',
      },
    ],
  },
  {
    name: 'Att hantera - Avier',
    category: 'to_handle',
    sub_category: 'invoice',
    modelNames: ['invoice'],
    statistics: [
      {
        name: 'Ej attesterade avier',
        identifier: 'to_handle.invoice.non_attested_invoices',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'invoice',
      },
      {
        name: 'Ej betalda avier',
        identifier: 'to_handle.invoice.not_paid_invoices',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Förfallna avier',
        identifier: 'to_handle.invoice.overdue_invoices',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Misslyckade sammanföringar',
        identifier: 'to_handle.invoice.merged_failed_invoices',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Misslyckade samavisering',
        identifier: 'to_handle.invoice.merged_failed_coinvoice',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Misslyckade autogirodragningar',
        identifier: 'to_handle.invoice.autogiro_failed',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Misslyckade avileveranser',
        identifier: 'to_handle.invoice.invoice_sendout_failed',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
    ],
  },
  {
    name: 'Att hantera - Fakturor',
    category: 'to_handle',
    sub_category: 'non_rental_invoice',
    modelNames: ['invoice'],
    statistics: [
      {
        name: 'Ej attesterade fakturor',
        identifier: 'to_handle.non_rental_invoice.non_attested_invoices',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'invoice',
      },
      {
        name: 'Ej betalda fakturor',
        identifier: 'to_handle.non_rental_invoice.not_paid_invoices',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Förfallna fakturor',
        identifier: 'to_handle.non_rental_invoice.overdue_invoices',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Misslyckade sammanföringar',
        identifier: 'to_handle.non_rental_invoice.merged_failed_invoices',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Misslyckade samavisering',
        identifier: 'to_handle.non_rental_invoice.merged_failed_coinvoice',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Misslyckade autogirodragningar',
        identifier: 'to_handle.non_rental_invoice.autogiro_failed',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
      {
        name: 'Misslyckade avileveranser',
        identifier: 'to_handle.non_rental_invoice.invoice_sendout_failed',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'invoice',
      },
    ],
  },
  {
    name: 'Att hantera - Betalningar',
    category: 'to_handle',
    sub_category: 'invoice_payment',
    modelNames: ['invoicepayment'],
    statistics: [
      {
        name: 'Ej matchade betalningar',
        identifier: 'to_handle.invoice_payment.non_matched_payments',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'invoicepayment',
      },
      {
        name: 'Överbetalningar',
        identifier: 'to_handle.invoice_payment.overpaid_payments',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'invoicepayment',
      },
    ],
  },
  {
    name: 'Att hantera - Objektsdatabas',
    category: 'to_handle',
    sub_category: 'apartment',
    modelNames: [
      'apartment',
      'brfapartment',
      'parkingspot',
      'commonarea',
      'industrialpremises',
      'outdoorsection',
    ],
    statistics: [
      {
        name: 'Vakanser - Lägenheter',
        identifier: 'to_handle.apartment.vacancy_objects',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'apartment',
      },
      {
        name: 'Vakanser - Bostadsrätter',
        identifier: 'to_handle.brf_apartment.vacancy_objects',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'brfapartment',
      },
      {
        name: 'Vakanser - Lokaler',
        identifier: 'to_handle.industrial_premises.vacancy_objects',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'industrialpremises',
      },
      {
        name: 'Vakanser - Utomhussektioner',
        identifier: 'to_handle.outdoor_section.vacancy_objects',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'outdoorsection',
      },
      {
        name: 'Vakanser - Fordonsplatser',
        identifier: 'to_handle.parking_spot.vacancy_objects',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'parkingspot',
      },
      {
        name: 'Vakanser - Gemensamma ytor',
        identifier: 'to_handle.common_area.vacancy_objects',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'commonarea',
      },
    ],
  },
  {
    name: 'Att hantera - IMD',
    category: 'to_handle',
    sub_category: 'imd',
    modelNames: ['sensor'],
    statistics: [
      {
        name: 'Ej matchade sensorer',
        identifier: 'to_handle.imd.not_matched_sensors',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'sensor',
      },
    ],
  },
  // {
  //   name: 'Att hantera - Godkännandeflöde',
  //   category: 'to_handle',
  //   sub_category: 'monitoring',
  //   modelNames: ['monitoringconfiguration'],
  //   statistics: [
  //     {
  //       name: 'Godkännanden',
  //       identifier: 'to_handle.monitoring.pending_approvals',
  //       pinned: false,
  //       color: widgetColors.blue,
  //       modelName: 'monitoringconfiguration',
  //     },
  //     {
  //       name: 'Retroaktiva debiteringar att godkänna',
  //       identifier: 'to_handle.monitoring.retro_activity_proposals',
  //       pinned: false,
  //       color: widgetColors.blue,
  //       modelName: 'monitoringconfiguration',
  //     },
  //     {
  //       name: 'Fördelningskvoter att godkänna',
  //       identifier: 'to_handle.monitoring.distribution_quota_proposals',
  //       pinned: false,
  //       color: widgetColors.blue,
  //       modelName: 'monitoringconfiguration',
  //     },
  //   ],
  // },
  {
    name: 'Att hantera - BRF',
    category: 'to_handle',
    sub_category: 'brf',
    modelNames: ['brfapartment'],
    statistics: [
      {
        name: 'Ej inrapporterade kontrolluppgifter',
        identifier: 'to_handle.brf.has_no_listed_control_tasks',
        pinned: false,
        color: widgetColors.yellow,
        modelName: 'brfapartment',
      },
      {
        name: 'Ej aktiva aviseringsavtal',
        identifier: 'to_handle.brf.has_no_active_invoicing_configuration',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'brfapartment',
      },
      {
        name: 'Saknar tillsvidare aviseringsavtal',
        identifier: 'to_handle.brf.has_no_indefinite_invoicing_configuration',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'brfapartment',
      },
    ],
  },
  {
    name: 'Att hantera - Bokföring',
    category: 'to_handle',
    sub_category: 'bookkeeping',
    modelNames: ['verificationgroup'],
    statistics: [
      {
        name: 'Verifikationsgrupper att attestera',
        identifier: 'to_handle.bookkeeping.verification_groups_to_attest',
        pinned: false,
        color: widgetColors.blue,
        modelName: 'verificationgroup',
      },
      {
        name: 'Verifikat som inte har exporterats till bokföringssystem (Visma)',
        identifier: 'to_handle.bookkeeping.verification_not_exported_visma',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'verification',
      },
      {
        name: 'Verifikat som inte har exporterats till bokföringssystem (Fortnox)',
        identifier: 'to_handle.bookkeeping.verification_not_exported_fortnox',
        pinned: false,
        color: widgetColors.destructive,
        modelName: 'verification',
      },
    ],
  },
];
