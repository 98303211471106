import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';

const NEVER_FETCH_NESTED_FIELDS = [
  'address',
  'tags',
  'mainAddress',
  'postalAddress',
  'invoiceAddress',
  'customerColumns',
  'previousAddress',
  'registeredAddress',
  'currentRegisteredAddress',
  'visitingAddress',
];

export function externalToInternalFunc<Instance extends BaseInstance>(
  nested: Array<keyof Instance>,
  config: IBaseInstanceConfig<Instance>
) {
  return nested
    ?.filter(
      (fieldName) => !NEVER_FETCH_NESTED_FIELDS.includes(fieldName as string)
    )
    ?.filter(
      (fieldName) =>
        config.fields?.[fieldName] &&
        (config.fields[fieldName]?.type === 'relation' ||
          config.fields[fieldName]?.type === 'manyrelation')
    )
    .map((fieldName) => ({
      [config.fields?.[fieldName]?.externalFieldName]: fieldName,
    }))
    .reduce<Record<string, keyof Instance>>(
      (prev, curr) => ({ ...prev, ...curr }),
      {}
    );
}

export type DateRange = 'day' | 'week' | 'month' | 'year' | null;

export type DateCategory = 'pending' | 'done';

type DateRangeReturn = {
  startDate: DateTime;
  endDate: DateTime;
};

export function getDateRange(
  range: DateRange,
  dateCategory: DateCategory
): DateRangeReturn | undefined {
  const isPending = dateCategory === 'pending';
  switch (range) {
    case 'day':
      return isPending
        ? {
            startDate: DateTime.now().plus({ days: 1 }),
            endDate: DateTime.now().plus({ days: 1 }),
          }
        : {
            startDate: DateTime.now(),
            endDate: DateTime.now(),
          };
    case 'week':
      return isPending
        ? {
            startDate: DateTime.now().plus({ days: 1 }),
            endDate: DateTime.now().plus({ days: 7 }),
          }
        : {
            startDate: DateTime.now().startOf('week'),
            endDate: DateTime.now(),
          };
    case 'month':
      return isPending
        ? {
            startDate: DateTime.now().plus({ days: 1 }),
            endDate: DateTime.now().plus({ months: 1 }),
          }
        : {
            startDate: DateTime.now().startOf('month'),
            endDate: DateTime.now(),
          };
    case 'year':
      return isPending
        ? {
            startDate: DateTime.now().plus({ days: 1 }),
            endDate: DateTime.now().plus({ years: 1 }),
          }
        : {
            startDate: DateTime.now().startOf('year'),
            endDate: DateTime.now(),
          };
  }

  return undefined;
}
