import { fetchApi } from '@/requests/api';
import { BASE_DASHBOARD_URL } from '@/requests/constants';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import type { WorkEngagementDashboard } from '../types';

export const WORK_ENGAGEMENT_QUERY_KEY = 'dashboard-workengagement';

export function useGetDashboardWorkEngagement(
  options?: Omit<UseQueryOptions<WorkEngagementDashboard[]>, 'queryKey'> & {
    workEngagementIds?: string[];
  }
) {
  return useQuery({
    ...options,
    queryKey: [WORK_ENGAGEMENT_QUERY_KEY, options?.workEngagementIds],
    queryFn: async () => {
      const res = await fetchApi({
        url: `${BASE_DASHBOARD_URL}/myworkday/dashboard/workengagement${
          options?.workEngagementIds?.length
            ? `?work_engagement_ids=${options.workEngagementIds.join(',')}`
            : ''
        }`,
        next: {
          revalidate: 0,
        },
      });
      const data = await res.json();
      return data;
    },
    staleTime: 60 * 5 * 1000,
    refetchOnWindowFocus: true,
  });
}
