import type { Widget } from '../types';
import { widgetColors } from '../utils';

export const systemEventsWidgets: Widget[] = [
  {
    name: 'Systemhändelser - Betalningar',
    category: 'system_events',
    sub_category: 'invoice_payments',
    modelNames: ['invoicepayment'],
    statistics: [
      {
        name: 'Splittade betalningar',
        identifier: 'system_events.invoice_payments.split_from_payment',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'invoicepayment',
      },
      {
        name: 'Inbetalningar inlästa',
        identifier:
          'system_events.invoice_payments.registered_invoice_payments',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'invoicepayment',
      },
      {
        name: 'Matchade betalningar',
        identifier: 'system_events.invoice_payments.matched_invoice_payments',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'invoicepayment',
      },
    ],
  },
  {
    name: 'Systemhändelser - IMD',
    category: 'system_events',
    sub_category: 'imd',
    modelNames: ['sensor'],
    statistics: [
      {
        name: 'Mätvärden inlästa',
        identifier: 'system_events.imd.measurements_received',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'sensor',
      },
    ],
  },
  {
    name: 'Systemhändelser - Notiser',
    category: 'system_events',
    sub_category: 'notifications',
    modelNames: ['notification'],
    statistics: [
      {
        name: 'Notiser som ej kunde skickas',
        identifier: 'system_events.notifications.failed_delivery',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'notification',
      },
    ],
  },
  {
    name: 'Systemhändelser - Lägenhetsavtal',
    category: 'system_events',
    sub_category: 'apartment_contracts',
    modelNames: ['apartmentcontract'],
    statistics: [
      {
        name: 'Lägenhetsavtal signerade',
        identifier: 'system_events.apartment_contracts.signed_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'apartmentcontract',
      },
      {
        name: 'Lägenhetsavtal uppsagda',
        identifier: 'system_events.apartment_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'apartmentcontract',
      },
      {
        name: 'Lägenhetsavtal utskickade för signering',
        identifier:
          'system_events.apartment_contracts.distributed_for_signature',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'apartmentcontract',
      },
      {
        name: 'Lägenhetsavtal som kommer att löpa ut',
        identifier: 'system_events.apartment_contracts.ending_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'apartmentcontract',
      },
    ],
  },
  {
    name: 'Systemhändelser - Lokalavtal',
    category: 'system_events',
    sub_category: 'industrialpremises_contracts',
    modelNames: ['industrialpremisescontract'],
    statistics: [
      {
        name: 'Lokalavtal signerade',
        identifier:
          'system_events.industrialpremises_contracts.signed_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'industrialpremisescontract',
      },
      {
        name: 'Lokalavtal uppsagda',
        identifier:
          'system_events.industrialpremises_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'industrialpremisescontract',
      },
      {
        name: 'Lokalavtal utskickade för signering',
        identifier:
          'system_events.industrialpremises_contracts.distributed_for_signature',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'industrialpremisescontract',
      },
      {
        name: 'Lokalavtal som kommer att löpa ut',
        identifier:
          'system_events.industrialpremises_contracts.ending_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'industrialpremisescontract',
      },
    ],
  },
  {
    name: 'Systemhändelser - Parkeringsavtal',
    category: 'system_events',
    sub_category: 'parkingspot_contracts',
    modelNames: ['parkingspotcontract'],
    statistics: [
      {
        name: 'Parkeringsavtal signerade',
        identifier: 'system_events.parkingspot_contracts.signed_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'parkingspotcontract',
      },
      {
        name: 'Parkeringsavtal uppsagda',
        identifier: 'system_events.parkingspot_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'parkingspotcontract',
      },
      {
        name: 'Parkeringsavtal utskickade för signering',
        identifier:
          'system_events.parkingspot_contracts.distributed_for_signature',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'parkingspotcontract',
      },
      {
        name: 'Parkeringsavtal som kommer att löpa ut',
        identifier: 'system_events.parkingspot_contracts.ending_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'parkingspotcontract',
      },
    ],
  },
  {
    name: 'Systemhändelser - Utomhussektionsavtal',
    category: 'system_events',
    sub_category: 'outdoorsection_contracts',
    modelNames: ['outdoorsectioncontract'],
    statistics: [
      {
        name: 'Utomhussektionsavtal signerade',
        identifier: 'system_events.outdoorsection_contracts.signed_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'outdoorsectioncontract',
      },
      {
        name: 'Utomhussektionsavtal uppsagda',
        identifier:
          'system_events.outdoorsection_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'outdoorsectioncontract',
      },
      {
        name: 'Utomhussektionsavtal utskickade för signering',
        identifier:
          'system_events.outdoorsection_contracts.distributed_for_signature',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'outdoorsectioncontract',
      },
      {
        name: 'Utomhussektionsavtal som kommer att löpa ut',
        identifier: 'system_events.outdoorsection_contracts.ending_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'outdoorsectioncontract',
      },
    ],
  },
  {
    name: 'Systemhändelser - Övriga avtal',
    category: 'system_events',
    sub_category: 'other_contracts',
    modelNames: ['othercontract'],
    statistics: [
      {
        name: 'Övriga avtal signerade',
        identifier: 'system_events.other_contracts.signed_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'othercontract',
      },
      {
        name: 'Övriga avtal uppsagda',
        identifier: 'system_events.other_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'othercontract',
      },
      {
        name: 'Övriga avtal utskickade för signering',
        identifier: 'system_events.other_contracts.distributed_for_signature',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'othercontract',
      },
      {
        name: 'Övriga avtal som kommer att löpa ut',
        identifier: 'system_events.other_contracts.ending_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'othercontract',
      },
    ],
  },
  {
    name: 'Systemhändelser - Blockavtal',
    category: 'system_events',
    sub_category: 'block_contracts',
    modelNames: ['blockcontract'],
    statistics: [
      {
        name: 'Blockavtal signerade',
        identifier: 'system_events.block_contracts.signed_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'blockcontract',
      },
      {
        name: 'Blockavtal uppsagda',
        identifier: 'system_events.block_contracts.cancelled_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'blockcontract',
      },
      {
        name: 'Blockavtal utskickade för signering',
        identifier: 'system_events.block_contracts.distributed_for_signature',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'blockcontract',
      },
      {
        name: 'Blockavtal som kommer att löpa ut',
        identifier: 'system_events.block_contracts.ending_contracts',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'blockcontract',
      },
    ],
  },

  {
    name: 'Systemhändelser - Avier',
    category: 'system_events',
    sub_category: 'invoices',
    modelNames: ['invoice'],
    statistics: [
      {
        name: 'Skapade avier',
        identifier: 'system_events.invoices.created_invoices',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'invoice',
      },
      {
        name: 'Avier attesterade',
        identifier: 'system_events.invoices.attested_invoices',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'invoice',
      },
      {
        name: 'Påminnelser som kommer att skickas ut',
        identifier: 'system_events.invoices.reminder_invoices',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'invoice',
      },
      {
        name: 'Kravpåminnelser som kommer att skickas ut',
        identifier: 'system_events.invoices.reminder_debt_invoices',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'invoice',
      },
      {
        name: 'Inkassokrav som kommer att skickas ut',
        identifier: 'system_events.invoices.inkasso_invoices',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'invoice',
      },
      {
        name: 'Avier som kommer att förfalla',
        identifier: 'system_events.invoices.expiring_invoices',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'pending',
        modelName: 'invoice',
      },
    ],
  },
  {
    name: 'Systemhändelser - Fakturor',
    category: 'system_events',
    sub_category: 'non_rental_invoices',
    modelNames: ['invoice'],
    statistics: [
      {
        name: 'Skapade fakturor',
        identifier: 'system_events.non_rental_invoices.created_invoices',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'invoice',
      },
      {
        name: 'Fakturor attesterade',
        identifier: 'system_events.non_rental_invoices.attested_invoices',
        pinned: false,
        color: widgetColors['light-gray'],
        dateCategory: 'done',
        modelName: 'invoice',
      },
    ],
  },
];
