 import type { OrganizationUser } from '@pigello/pigello-matrix';

import { OrganizationUserConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetOrganizationUser = (
  options: useGetGenericInstanceProps<OrganizationUser>
) => {
  return useGetInstance<OrganizationUser>({
    ...options,
    config: OrganizationUserConfig,
    modelName: 'organizationuser'
  });
};

export const useGetOrganizationUserList = (
  options: useGetGenericListProps<OrganizationUser>
) => {
  return useGetList<OrganizationUser>({
    ...options,
    config: OrganizationUserConfig,
    modelName: 'organizationuser'
  });
};
